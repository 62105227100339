import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Pagination from '../../components/Pagination'
import { RuxDialog, RuxButton, RuxStatus } from '@astrouxds/react'
import { getTleHistory, changeTleEntryStatus, deleteTleEntry, getTleDetail } from '../../apis/tleApi'
import { useAuth } from '../../auth/AuthContext'
import { notify } from '../../libs/notificationSystem/notificationManager'

const TleTable = () => {
    const { hasPermission } = useAuth()
    const [tleHistory, setTleHistory] = useState([])
    const [tleDialogData, setTleDialogData] = useState(null)

    const [pageInfo, setPageInfo] = useState({
        page: 0,
        perPage: 20
    })
    const [totalEntries, setTotalEntries] = useState(0)
    
    const loadTleHistory = async () => {
        try {
            const res = await getTleHistory(pageInfo.page*pageInfo.perPage, pageInfo.perPage, true)
            setTleHistory(res.data)
            setTotalEntries(res.entries)
        } catch (error) {
            console.error('Error loading TLE history:', error)
            notify(`Error loading TLE history: ${error.message}`, 'critical')
        }
    }

    const loadTleDetail = async (tle_file_id) => {
        try {
            const res = await getTleDetail(tle_file_id)
            setTleDialogData( data => tle_file_id === data.tle_file_id ? {tle_file_id, tle: res.tle} : data)
        } catch (error) {
            console.error('Error loading TLE detail:', error)
            notify(`Error loading TLE detail: ${error.message}`, 'critical')
        }
    }

    useEffect(() => {

        loadTleHistory()

        const interval = setInterval(async () => {
            await loadTleHistory()
        }, 20000);

        return () => clearInterval(interval)
    }, [pageInfo])


    const handleShowDetailClick = (tle_file_id) => {
        setTleDialogData({
            tle_file_id: tle_file_id,
            tle: "Data loading..."
        })
        loadTleDetail(tle_file_id)
    }

    const handleChangeTleStatusClick = async (id, isActive) => {        
        try {
            await changeTleEntryStatus(id, {
                is_active: isActive
            })
            console.log(`TLE entry with ID ${id} update initiated`)
            notify(`TLE entry update initiated`, 'normal')
            loadTleHistory()
        } catch (error) {
            console.error(`Error updating TLE entry with ID ${id}`)
            console.error(error)
            notify(`Error updating TLE entry with ID ${id}: ${error.message}`, 'critical')
        }
    }

    const handleDeleteTleClick = async (id) => {
        try {
            await deleteTleEntry(id)
            console.log(`TLE entry with ID ${id} deleted successfully`)
            notify(`TLE entry deleted successfully`, 'normal')
            loadTleHistory()
        } catch (error) {
            console.error(`Error deleting TLE entry with ID ${id}`)
            console.error(error.message)
            notify(`Error deleting TLE entry with ID ${id}: ${error.message}`, 'critical')
        }
    }

    const timeToNow = (time) => {
        const now = new Date();
        const tleTime = new Date(time);
        const isToday = moment(tleTime).utc().format('DD.MM.YYYY') === moment(now).utc().format('DD.MM.YYYY');

        let time1
        if(isToday) {
            time1 = moment(tleTime).fromNow()
        } else {
            time1 = moment(tleTime).utc().format('DD.MM.YYYY')
        }
        const time2 = moment(tleTime).utc().format('HH:mm:ss')

        return (
            <span>
                {time1}
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                    {time2}
                </span>
            </span>
        );
    };

    const tleType = (account) => {
        return (
            <span>
                {account ? "Private" : "General"}
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                    {account ? account.account_name : "No Account"}
                </span>
            </span>
        )
    }

    const renderStatusIcon = (tle) => {
        return tle.is_active ? 'normal' : 'critical'
    }
    const renderButtonIcon = (tle) => {
        return tle.is_active ? 'remove-circle-outline' : 'add-circle-outline'
    }

    const tleDetailDialog = (
        <RuxDialog open={tleDialogData !== null} header='TLE Detail'>
            {tleDialogData && <pre style={{fontSize: '0.75em'}}>{tleDialogData.tle}</pre>}
            <div slot='footer' onClick={() => setTleDialogData(null)}>
                <RuxButton secondary style={{ float: 'right' }} id="confirm">
                    Close
                </RuxButton>
            </div>
        </RuxDialog>
    )

    

    return (
        <div>
            <rux-table>
                <rux-table-body>
                    <rux-table-header-row selected="false">
                        <rux-table-header-cell></rux-table-header-cell>
                        <rux-table-header-cell>Title</rux-table-header-cell>
                        <rux-table-header-cell>Time</rux-table-header-cell>
                        <rux-table-header-cell>Type</rux-table-header-cell>
                        <rux-table-header-cell></rux-table-header-cell>
                    </rux-table-header-row>
                    {tleHistory.map((t, i) => (
                        <rux-table-row key={i} selected="false">
                            <rux-table-cell>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <RuxStatus status={renderStatusIcon(t)} />
                                    {(hasPermission('tle_manage_all') || (hasPermission('tle_manage_my') && t.account)) && (
                                        <span style={{ marginLeft: '1em' }}>
                                            <RuxButton secondary icon-only icon={renderButtonIcon(t)} borderless onClick={() => handleChangeTleStatusClick(t.tle_file_id, !t.is_active)}/>
                                        </span>
                                    )}
                                </span>
                            </rux-table-cell>
                            <rux-table-cell>{t.title}</rux-table-cell>
                            <rux-table-cell>{timeToNow(t.time)}</rux-table-cell>
                            <rux-table-cell>{tleType(t.account)}</rux-table-cell>
                            <rux-table-cell style={{textAlign: 'right'}}>
                                <RuxButton secondary icon-only icon="info" borderless onClick={() => handleShowDetailClick(t.tle_file_id)}/>
                                {(hasPermission('tle_manage_all') || (hasPermission('tle_manage_my') && t.account)) && (
                                    <RuxButton secondary icon-only icon="remove-circle-outline" borderless onClick={() => handleDeleteTleClick(t.tle_file_id)}/>
                                )}
                            </rux-table-cell>
                        </rux-table-row>
                    ))}
                </rux-table-body>
            </rux-table>
            <div style={{height: '50px'}}>
                <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} totalEntries={totalEntries}/>
            </div>
            {tleDetailDialog}
        </div>
    )
}   

export default TleTable