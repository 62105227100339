import api from './config/axiosConfig';

const PREDICTION_API_BASE_URL = 'scheduling/predictions';
const UPCOMING_PREDICTION_API_BASE_URL = '/scheduling/predictions/upcoming';

export const getPredictions = async (objects, stations, time_from, time_to, offset, limit, sendEntries=false) => {
    const requestPayload = {
        objects: objects,
        stations: stations,
        time_from: time_from,
        time_to: time_to,
    };

    let queryString = '';

    const params = new URLSearchParams();

    if (offset) {
        params.append('offset', offset.toString())
    }
    if (limit) {
        params.append('limit', limit.toString())
    }

    if (params.toString()) {
        queryString = `?${params.toString()}`;
    }

    const response = await api.post(`${PREDICTION_API_BASE_URL}${queryString}`, requestPayload, {
        timeout: 60000
    })

    return sendEntries ? response : response.data;
};

// export const getUpcomingPredictions = () => {
//     return api.get(UPCOMING_PREDICTION_API_BASE_URL);
// };
